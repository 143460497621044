let dollarval = 75;
let books = [
    {
        img: 'https://m.media-amazon.com/images/I/91km9a7X9GL._AC_UY218_.jpg',
        title: 'The Dragon Prophecy: The Fourth Adventure In The Kingdom Of Fantasy: 4 (Geronimo Stilton)',
        author: 'Geronimo Stilton ',
        price: dollarval,
        mrp: 349
    },
    {
        img: 'https://m.media-amazon.com/images/I/91L9MFXhhGL._AC_UY218_.jpg',
        title: 'Fantastic Mr Fox',
        author: 'Roald Dahl',
        price: dollarval,
        mrp: 235
    },
    {
        img: 'https://m.media-amazon.com/images/I/91e3+GzPgkL._AC_UY218_.jpg',
        title: 'The Suitcase Kid',
        author: 'Jacqueline Wilson',
        price: dollarval,
        mrp: 279
    },
    {
        img: 'https://m.media-amazon.com/images/I/81HPv6PnpCS._AC_UY218_.jpg',
        title: 'The Jungle Book',
        author: 'Rudyard Kipling',
        price: dollarval,
        mrp: 150
    },
    {
        img: 'https://m.media-amazon.com/images/I/61BGIJxdmuL._AC_UY218_.jpg',
        title: 'The Twits',
        author: 'Roald Dhal',
        price: dollarval,
        mrp: 341
    },
    {
        img: 'https://m.media-amazon.com/images/I/81Krp8WY10L._AC_UY218_.jpg',
        title: 'The Famous Five Collection 4: Books 10-12 (Famous Five: Gift Books And Collections)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 347
    },
    {
        img: 'https://m.media-amazon.com/images/I/91SbOZDHMWL._AC_UY218_.jpg',
        title: 'Song Of India',
        author: 'Ruskin Bond',
        price: dollarval,
        mrp: 211
    },
    {
        img: 'https://m.media-amazon.com/images/I/41CEHZDMSWL._AC_UY218_.jpg',
        title: 'The House On Falling Star Hill',
        author: 'Michael Molly',
        price: dollarval,
        mrp: "Currently Unavailable on all websites, only available here"
    },
    {
        img: 'https://m.media-amazon.com/images/I/91KC7WiUbwL._AC_UY218_.jpg',
        title: 'Sachin: A Hundred Hundreds Now',
        author: 'Krishnaswamy V',
        price: dollarval,
        mrp: 268
    },
    {
        img: 'https://m.media-amazon.com/images/I/91n+62Ow0WL._AC_UY218_.jpg',
        title: 'Good Old Secret Seven: 12 (The Secret Seven Series)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 125
    },
    {
        img: 'https://m.media-amazon.com/images/I/51l0TFEAylL._AC_UY218_.jpg',
        title: 'Ruby the Red Fairy: The Rainbow Fairies Book 1 (Rainbow Magic)',
        author: 'Daisy Meadows',
        price: dollarval,
        mrp: 451
    },
    {
        img: 'https://m.media-amazon.com/images/I/71WNM9heAAL._AC_UY218_.jpg',
        title: 'Rainbow Magic: Amber The Orange Fairy: The Rainbow Fairies Book 2',
        author: 'Daisy Meadows',
        price: dollarval,
        mrp: 202
    },
    {
        img: 'https://m.media-amazon.com/images/I/61MltHqWAbL._AC_UY218_.jpg',
        title: 'The Princess Diaries : Give Me Five',
        author: 'Meg Cabot',
        price: dollarval,
        mrp: 277
    },
    {
        img: 'https://m.media-amazon.com/images/I/81t6TfZoQRL._AC_UY218_.jpg',
        title: 'Secret Seven On The Trail: 4 (The Secret Seven Series)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 148
    },
    {
        img: 'https://m.media-amazon.com/images/I/91ldtyrQHrL._AC_UY218_.jpg',
        title: 'Secret Seven Mystery: 9 (The Secret Seven Series)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 144
    },
    {
        img: 'https://m.media-amazon.com/images/I/51ACbMpRYtL._AC_UY218_.jpg',
        title: 'Follow Your Heart: Finding Purpose In Your Life And Your Work',
        author: 'Andrew Matthews',
        price: dollarval,
        mrp: 279
    },
    {
        img: 'https://m.media-amazon.com/images/I/81E+tITaNNL._AC_UY218_.jpg',
        title: 'Eleven Minutes',
        author: 'Paulo Coelho',
        price: dollarval,
        mrp: 350
    },
    {
        img: 'https://m.media-amazon.com/images/I/51+oaKkkqJL._AC_UY218_.jpg',
        title: 'I Heart Paris',
        author: 'Lindsey Kelk',
        price: dollarval,
        mrp: 441
    },
    {
        img: 'https://m.media-amazon.com/images/I/81HgnwDxH2L._AC_UY218_.jpg',
        title: 'Geronimo Stilton: The Hunt for the 100th Key',
        author: 'Geronimo Stilton',
        price: dollarval,
        mrp: 595
    },
    {
        img: 'https://m.media-amazon.com/images/I/71908bxGUDL._AC_UY218_.jpg',
        title: 'The Invisible Man (Children Classics)',
        author: 'H.G. Wells',
        price: dollarval,
        mrp: 200
    },
    {
        img: 'https://m.media-amazon.com/images/I/91J9J9tvZ9L._AC_UY218_.jpg',
        title: 'Genie Us',
        author: 'Linda Chapman',
        price: dollarval,
        mrp: 348
    },
    {
        img: 'https://m.media-amazon.com/images/I/91RJTDnPBEL._AC_UY218_.jpg',
        title: 'Alice in Wonderland : Illustrated Abridged Classics(Om Illustrated Classics)',
        author: 'Lewis Carroll',
        price: dollarval,
        mrp: 199
    },
    {
        img: 'https://m.media-amazon.com/images/I/51vSWrplbxL._AC_UY218_.jpg',
        title: 'Five Get Into Trouble: 8 (The Famous Five Series)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 199
    },
    {
        img: 'https://m.media-amazon.com/images/I/91BUuCqCVzL._AC_UY218_.jpg',
        title: 'The Valley Of Adventure (The Adventure Series)',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 325
    },
    {
        img: 'https://m.media-amazon.com/images/I/810DvwOON5L._AC_UY218_.jpg',
        title: 'Sundae Girl',
        author: 'Cathy Cassidy',
        price: dollarval,
        mrp: 299
    },
    {
        img: 'https://m.media-amazon.com/images/I/71Hthuc4UAL._AC_UY218_.jpg',
        title: 'A Darkling Plain 15th Anniversary Edition (Mortal Engines #4) (Mortal Engines Quartet)',
        author: 'Philip Reeve',
        price: dollarval,
        mrp: 395
    },
    {
        img: 'https://m.media-amazon.com/images/I/51cH-h5HMYL._AC_UY218_.jpg',
        title: 'James And The Giant Peach',
        author: 'Roald Dahl',
        price: dollarval,
        mrp: 399
    },
    {
        img: 'https://m.media-amazon.com/images/I/7195fCTE5wL._AC_UY218_.jpg',
        title: 'Swami And Friends',
        author: 'R. K. Narayan',
        price: dollarval,
        mrp: 215
    },
    {
        img: 'https://m.media-amazon.com/images/I/415AOPI8wnL._AC_UY218_.jpg',
        title: 'Malgudi Days',
        author: 'R. K. Narayan',
        price: dollarval,
        mrp: 225
    },
    {
        img: 'https://m.media-amazon.com/images/I/51CRwnD68CL._AC_UY218_.jpg',
        title: 'Childrens Omnibus',
        author: 'Ruskin Bond',
        price: dollarval,
        mrp: 200
    },
    {
        img: 'https://m.media-amazon.com/images/I/81x0FpXbgdL._AC_UY218_.jpg',
        title: 'Stuart Little (A Puffin Book)',
        author: 'E. B. White',
        price: dollarval,
        mrp: 299
    },
    {
        img: 'https://m.media-amazon.com/images/I/51mV7zUMQhL._AC_UY218_.jpg',
        title: 'The Dreadful Beauty',
        author: 'Sunil Gangopadhyay',
        price: dollarval,
        mrp: 150
    },
    {
        img: 'https://m.media-amazon.com/images/I/719FdfKQ3YL._AC_UY218_.jpg',
        title: 'The Children at Green Meadows',
        author: 'Enid Blyton',
        price: dollarval,
        mrp: 260
    },
    {
        img: 'https://m.media-amazon.com/images/I/51MfTkodduL._AC_UY218_.jpg',
        title: 'Pride & Prejudice',
        author: 'S Chand',
        price: dollarval,
        mrp: 203
    },
    {
        img: 'https://m.media-amazon.com/images/I/816lIDekolL._AC_UY218_.jpg',
        title: 'A Tale Of Two Cities',
        author: 'S Chand',
        price: dollarval,
        mrp: 217
    },
    {
        img: 'https://m.media-amazon.com/images/I/81ngjQe5w3L._AC_UY218_.jpg',
        title: 'More Tales From Shakespeare',
        author: 'S Chand',
        price: dollarval,
        mrp: 210
    },
    {
        img: 'https://m.media-amazon.com/images/I/516M83bX4ML._AC_UY218_.jpg',
        title: 'Tales From Shakespeare',
        author: 'S Chand',
        price: dollarval,
        mrp: 210
    },
    {
        img: 'https://m.media-amazon.com/images/I/71Wcm0WWBnS._AC_UY218_.jpg',
        title: 'The Puffin Book Of Classic School Stories',
        author: 'Ruskin Bond',
        price: dollarval,
        mrp: 250
    },
    {
        img: 'https://m.media-amazon.com/images/I/91YS0h1kz5L._AC_UY218_.jpg',
        title: 'Charlottes Web (A Puffin Book)',
        author: 'E. B. White',
        price: dollarval,
        mrp: 299
    },
    {
        img: 'https://m.media-amazon.com/images/I/81eCQRqumPL._AC_UY218_.jpg',
        title: 'Charlie and the Chocolate Factory',
        author: 'Roald Dahl',
        price: dollarval,
        mrp: 399
    },
    {
        img: 'https://m.media-amazon.com/images/I/91PUtoDKMHL._AC_UY218_.jpg',
        title: 'Fourth Grade Rats (Apple Paperbacks)',
        author: 'Jerry Spinelli',
        price: dollarval,
        mrp: 415
    },
    {
        img: 'https://m.media-amazon.com/images/I/91DjUnljyYL._AC_UY218_.jpg',
        title: 'The Maze Runner',
        author: 'James Dashner',
        price: dollarval,
        mrp: 395
    },
    {
        img: 'https://m.media-amazon.com/images/I/91PPEVsuK4L._AC_UY218_.jpg',
        title: 'Magic Tree House 2: Castle Of Mystery',
        author: 'Mary Pope Osborne',
        price: dollarval,
        mrp: 299
    },
    {
        img: 'https://m.media-amazon.com/images/I/81mGKF3nx0L._AC_UY218_.jpg',
        title: 'The Wizard Of Oz (Puffin Classics)',
        author: 'Cornelia Funke',
        price: dollarval,
        mrp: 224
    },
    {
        img: 'https://m.media-amazon.com/images/I/61xFIn9cpFL._AC_UY218_.jpg',
        title: 'The Adventure Of Tom Sawyer',
        author: 'Mark Twain',
        price: dollarval,
        mrp: 200
    },
];

export {books};