import React from 'react'
import ReactDOM from 'react-dom'
import Book from './Book'
import {books} from './books'
import emailjs from 'emailjs-com'

import './index.css';

//const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

let cartItems = [];
let price = 0;
let items = 0;

function Cart() {
  function deleteItem(cartItem) {
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].title === cartItem.title) {
        cartItems.splice(i, 1);
        price -= cartItem.price + 25;
        alert("Item deleted from cart")
        items--;
        if (items === 0) {
          document.getElementById('submit1').disabled = true;
          document.getElementById('form').style.display = 'none';
        } else {
          document.getElementById('submit1').disabled = false;
        }
        break;
      }
    }
    ReactDOM.render(<BookList />, document.getElementById('root'))
  }
  if (cartItems.length > 0) {
    return (
      cartItems.map((cartItem) => {
        return (
          <section>
            <Book img={cartItem.img} price={cartItem.price} title={cartItem.title} author={cartItem.author} mrp={cartItem.mrp} /><br></br>
            <button className="delete" id={cartItem.title} onClick={function () {deleteItem(cartItem)}}>Delete From Cart</button>
          </section>
        )
      })
    )
  } else {
    return <div></div>
  }
}

function BookList() {
  function sendEmail(e) {
    if (items > 0) {
      e.preventDefault();
      emailjs.sendForm('service_earvw9s', 'template_77xa2a3', document.getElementById('form'), 'user_R30gLH12KudQmnwElJvJx').then(res=>{
        console.log(res)
      }).catch(err=>{
        console.log(err)
      });
      displayRazorpay(price);
    }
  }

  function check() {
    var z = document.getElementsByClassName('myInput');
    if (z[0].value !== '' && z[1].value !== '' && z[2].value !== '' && z[3].value !== '') {
      document.getElementById('submit').disabled = false;
    } else {
      document.getElementById('submit').disabled = true;
    }
  }
  /*const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price,
          },
        },
      ],
    });
  }
  const onApprove = (data, actions) => {
    return actions.order.capture();
  }*/
  function addItem(book) {
    var toggle = 0;
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].title === book.title) {
        toggle = 1;
        break;
      }
    }
    if (toggle === 0) {
      alert('Item added to cart');
      cartItems.push({img: book.img, price: book.price, title: book.title, author: book.author, mrp: book.mrp});
      price += book.price+25;
      items++;
      document.getElementById('submit1').disabled = false;
      ReactDOM.render(<BookList />, document.getElementById('root'))
    }
  }

  function show() {
    document.getElementById('form').style.display = 'block';
  }

  function loadscript(url) {
    return new Promise(function(resolve) {
      const script = document.createElement('script');
      script.src = url;

      script.onload = function() {
        resolve(true);
      }

      script.onerror = function() {
        resolve(false);
        window.location.reload();
      }

      document.body.appendChild(script);
    })
  }

  async function displayRazorpay(money) {
    if (cartItems.length > 0) {
      const res = await loadscript('https://checkout.razorpay.com/v1/checkout.js')

      if (!res) {
        alert('Sorry, our payment service failed to load. Please try again later');
        return;
      }

      const options = {
        key: "rzp_live_MvlVhI8wFweeOl",
        currency: "INR",
        amount: (price * 100),
        name: "Checkout",
        description: "Thanks for purchasing",
        image: "https://themysterybox.10xcoderkids.repl.co/logo.jpeg",

        handler: function (response) {
          alert('Payment Successful');
        },
        prefill: {
          name: '10xCoderKids'
        }
      }

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }

  return (
    <section>
      <h1 id="cart">Cart ({items} items in your cart)</h1>
      <h1 id="totaltext">Total: ₹{price}</h1><br></br>
      <button id="submit1" className="checkout" onClick={show}>Checkout</button>
      <form method="POST" id="form" onSubmit={sendEmail}>
        Name: &emsp;<input onChange={check} id="name" className="myInput" type="text" name="name" required/><br></br><br></br>
        Address: <input onChange={check} id="address" className="myInput" type="text" size="50" name="address" required/><br></br><br></br>
        Email:&emsp;&nbsp;&nbsp;<input onChange={check} id="email" className="myInput" type="email" name="email" required/><br></br><br></br>
        Phone:&emsp;<input onChange={check} id="phone" className="myInput" type="tel" pattern="[0-9]{10}" name="phone" required/><br></br><br></br>
        <input type="hidden" name="booksend" id="booksend" value={JSON.stringify(cartItems)} />
        <button id="submit" type="submit" className="checkout">Make Payment</button>
      </form>
      <Cart />
      {/*<PayPalButton
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />*/}<br></br>
      <h1 id="ouritems">Books you can buy ({books.length} books remaining)</h1>
      {books.map((book) => {
        return (
          <section>
            <Book img={book.img} price={book.price} title={book.title} author={book.author} mrp={book.mrp} /><br></br>
            <button className="add" onClick={function () {addItem(book)}}>Add To Cart</button>
          </section>
        )
      })}
    </section>
  )
}

ReactDOM.render(<BookList />, document.getElementById('root'))