import React from 'react'

export default function Book({img, title, price, author, mrp}) {
    return (
        <article id="book">
            <img id="bookimg" src={img} alt="" className="bookimage" />
            <div id="wrapper">
                <h3 className="title">{title}</h3>
                <h3 className="author">{author}</h3><br></br>
                <h3 className="price">₹{price} + (₹25 delivery charges)</h3>
                <h3 id="mrp">₹{mrp}</h3>
            </div>
        </article>
    )
}